import React, { useEffect, useState } from "react";
import "./Display.css";
import locationIcon from "../images/location.png"
import { getAllMtos, getMtosByDistrict, getMtosByVillage, getBaseUrl } from "../api";
import InfiniteScroll from 'react-infinite-scroll-component';

export default function MTOs(props) {
  
  const {usedLanguage} = props;
  const [mtos, setMtos] = useState([]);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalMTOs, setTotalMTOs] = useState(0);
  
  const fetchData = async (reset) => {
    try {
      
      let page = 1;

      if(!reset) {
        page = currentPage;
      }

      let data = [];

      let mtosList = [];

      if(props.districtCode && !props.villagePcode) {
        mtosList = await getMtosByDistrict(props.districtCode, page);
      } else if(props.villagePcode) {
        mtosList = await getMtosByVillage(props.villagePcode, page);
      } else {
        mtosList = await getAllMtos(page);
      }

      if(mtosList.last_page > page)  {
        setCurrentPage(page + 1);
      } else {
        setHasMoreData(false);
      }

      if(reset) {
        data = mtosList.data;
      } else {
        data = [...mtos, ...mtosList.data];
      }

      setTotalMTOs(mtosList.total);
      setMtos(data);
      
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  useEffect(() => {
    setHasMoreData(true);
    setCurrentPage(1);

    fetchData(true);
  }, [props.districtCode, props.villagePcode]);

  function getTranslation(translations, locale, field) {
    const translation = translations.find(t => t.locale.locale === locale);
    return translation ? translation[field] : '';
  }

  function getTranslationNoLocaleObj(translations, locale, field) {
    const translation = translations.find(t => t.locale === locale);
    return translation ? translation[field] : '';
  }

  const lengthStatement = `${props.mtosStatment} ${totalMTOs}`;

  return (
    <div className="container">
      <div className="container-sub">
        <h4 className='num'>{lengthStatement}</h4>
        <hr className="line" />
        <InfiniteScroll
          dataLength={mtos.length}
          next={fetchData}
          hasMore={hasMoreData}
          loader={<p className="load" >{props.oading}</p>}
          endMessage={<p className="load" >{props.dataLoad}</p>}
        >
          <div className="List">
            {mtos.map((mto, index) => (
              <div className="divStyle" key={index}>
                <div className="vcenter">
                  <img src={getBaseUrl() + "storage/" + mto.image_path} className="img" alt="MTO" />
                </div>
                <div>
                  <h3 className="name">{getTranslation(mto.translations, usedLanguage, 'name')}</h3>
                  <p className="details">{getTranslationNoLocaleObj(mto.district.translations, usedLanguage, 'name')} - {getTranslationNoLocaleObj(mto.village.translations, usedLanguage, 'name')}</p>
                  <p className="details">{getTranslation(mto.translations, usedLanguage, 'address')}</p>
                  <p className="details">{getTranslation(mto.translations, usedLanguage, 'opening_days')}</p>
                  <p className="details">{getTranslation(mto.translations, usedLanguage, 'opening_time')}</p>
                  <p className="details">{mto.phone_number}</p>
                  <a  href={`https://www.google.com/maps/search/?api=1&query=${mto.latitude},${mto.longitude}`} target="_blank" rel="noreferrer">
                  <button>
                    <img src={locationIcon} alt="Location" className="buttonIcon"/>
                    <div className="buttonText">
                    {props.location}
                    </div>
                  </button>
              </a>
                </div>
              </div>
            ))}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
}
