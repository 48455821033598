import React, { useEffect, useState } from "react";
import "./Display.css";
import locationIcon from "../images/location.png"
import { getAllShops, getShopByDistrict, getShopByVillage, getBaseUrl } from "../api";
import InfiniteScroll from 'react-infinite-scroll-component';

export default function Shops(props) {
  const { usedLanguage } = props;
  const [shops, setShops] = useState([]);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalShops, setTotalShops] = useState(0); 
  const [districtCode, setDistrictCode] = useState(null);

  const fetchData = async (reset) => {
    try {
      
      let page = 1;

      if(!reset) {
        page = currentPage;
      }

      let data = [];

      let shopsList = [];

      if(props.districtCode && !props.villagePcode) {
        shopsList = await getShopByDistrict(props.districtCode, page);
      } else if(props.villagePcode) {
        shopsList = await getShopByVillage(props.villagePcode, page);
      } else {
        shopsList = await getAllShops(page);
      }

      if(shopsList.last_page > page)  {
        setCurrentPage(page + 1);
      } else {
        setHasMoreData(false);
      }

      if(reset) {
        data = shopsList.data;
      } else {
        data = [...shops, ...shopsList.data];
      }

      setTotalShops(shopsList.total);
      setShops(data);
      setDistrictCode(props.districtCode);
      
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  useEffect(() => {
    setHasMoreData(true);
    setCurrentPage(1);

    fetchData(true);
  }, [props.districtCode, props.villagePcode]);

  function getTranslation(translations, locale, field) {
    const translation = translations.find(t => t.locale === locale);
    return translation ? translation[field] : '';
  }

  const lengthStatment =`${props.shopsStatment} ${totalShops}`;

  return (
    <div className="container">
    <div className="container-sub">
      <h4 className='num'>{lengthStatment}</h4>
      <hr className="line" />
      <InfiniteScroll
        dataLength={shops.length}
        next={fetchData}
        hasMore={hasMoreData}
        loader={<p className="load" >{props.loading}</p>}
        endMessage={<p className="load" >{props.dataLoad}</p>}
        >
      <div className="List">
        {shops.map((shop, index) => (
          <div className="divStyle" key={index}>
            <div className="vcenter">
              <img src={getBaseUrl() + "storage/" + shop.image_path} className="img"/>
            </div>
            <div>
              <h3 className="name">{getTranslation(shop.translations, usedLanguage, 'name')}</h3>
              <p className="details">{shop.wfp_shop_id}</p>
              <p className="details">{getTranslation(shop.district.translations, usedLanguage, 'name')} - {getTranslation(shop.village.translations, usedLanguage, 'name')}</p>
              <p className="details">{getTranslation(shop.translations, usedLanguage, 'address')}</p>
              <a  href={`https://www.google.com/maps/search/?api=1&query=${shop.latitude},${shop.longitude}`} target="_blank">
                  <button>
                    <img src={locationIcon} alt="Location" className="buttonIcon"/>
                    <div className="buttonText">
                    {props.location}
                    </div>
                  </button>
              </a>
            </div>
          </div>
        ))}
      </div>
      </InfiniteScroll>
    </div>
    </div>
  );
}
